/* eslint-disable */
import { mat4 } from "gl-matrix";

export class Feet3D
{
  // export const footImage = new Image();
  // // footImage.src = "foot_sample2.png";
  // export const onImageLoad = () => {
  //   imageLoaded = true;
  // };
  // footImage.onload = onImageLoad;


  constructor()
  {
    this.N = 250;
    this.imageLoaded = false;
    this.hasValidImage = false;
    this.rotX = -3.14;
    this.rotY = 3.14;
    this.threshold = 127;
    this.contrast = 80;
    this.footImage = new Image();
    this.gl2Mode = true;
  }
  
  
  loadImage(imageSrc)
  {
    this.imageLoaded = false;
    this.hasValidImage = false;
    var that = this;
    this.footImage.src = imageSrc;
    this.footImage.onload = () => {
      that.imageLoaded = true;
    };
  }
  
  //
  // Start here
  //
  async rendererMain(footImage) {
    const canvas = document.querySelector("#glcanvas");
    var gl = canvas.getContext("webgl2") || canvas.getContext("experimental-webgl2");
    
    if (!gl)
    {
      this.N = 100;
      this.gl2Mode = false;
      gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    }
    
    // If we don't have a GL context, give up now

    if (!gl) {
      alert("A böngésző nem támogatja a WebGL-t, nincs 3D megjelenítés");
      return;
    }

    // Vertex shader program

    const vsSource = `
    attribute vec4 aVertexPosition;
    
    uniform mat4 uModelViewMatrix;
    uniform mat4 uInvTModelViewMatrix;
    uniform mat4 uProjectionMatrix;
    
    precision lowp float;
    
    varying lowp vec3 vPos;
    varying lowp vec3 vNormal;
    
    uniform sampler2D imageSampler;
    uniform float threshold;
    uniform float contrast;
    
    float transformValue(float x)
    {
      float v = (x - threshold/255.0) * (1.0+contrast/10.0);
      return 1.0 / (exp(-v) + 1.0);
    }
    
    float normalizedTransform(float x)
    {
      float A = transformValue(0.0);
      float B = transformValue(1.0);
      
      return (transformValue(x)-A) / (B-A);
    }
    
    vec4 flip(vec4 v)
    {
      v.x *= -1.0;
      return v;
    }
    
    void main(void)
    {
      vec2 vTextureCoord = (vec2(flip(aVertexPosition)) + 1.0) / 2.0;
      float depth = texture2D(imageSampler, vTextureCoord).x;
      vec4 shitedvPos = flip(aVertexPosition);
      
      depth = normalizedTransform(depth);
      
      shitedvPos.z += depth*0.3;
      
      gl_Position = uProjectionMatrix * uModelViewMatrix * shitedvPos;
      vPos = vec3(flip(aVertexPosition));
      
      float h = 1.0/300.0;
      
      float depthA = texture2D(imageSampler, vTextureCoord + vec2( -h,  0)).x/3.0;
      float depthB = texture2D(imageSampler, vTextureCoord + vec2(  h,  0)).x/3.0;
      float depthC = texture2D(imageSampler, vTextureCoord + vec2(  0, -h)).x/3.0;
      float depthD = texture2D(imageSampler, vTextureCoord + vec2(  0,  h)).x/3.0;
      
      float beta = atan(2.0*h, depthA-depthB);
      float alpha = atan(2.0*h, depthC-depthD);
      
      vec3 norm = -vec3(cos(beta)*sin(alpha),sin(beta)*sin(alpha),cos(alpha));
      
      vNormal = mat3(uInvTModelViewMatrix) * norm;
    }
    `;

    // Fragment shader program

    const fsSource = `
    varying lowp vec3 vPos;
    varying lowp vec3 vNormal;
    
    precision lowp float;
    
    uniform sampler2D imageSampler;
    uniform vec3 viewPos;
    
    uniform float threshold;
    uniform float contrast;
    
    float transformValue(float x)
    {
      float v = (x - threshold/255.0) * (1.0+contrast/10.0);
      return 1.0 / (exp(-v) + 1.0);
    }
    
    float normalizedTransform(float x)
    {
      float A = transformValue(0.0);
      float B = transformValue(1.0);
      
      return (transformValue(x)-A) / (B-A);
    }
    
    #define M_PI 3.1415926535897932384626433832795
    
    lowp vec3 heatmap3c(float x)
    {
      float r = 0.0;
      
      if (x > 0.5)
      {
        r = cos(M_PI*(x-1.0));
      }
      else
      {
        r = 0.0;
      }
      
      float g = sin(M_PI*x);
      float b = 0.0;
      
      if (x < 0.5)
      {
        b = cos(M_PI*x);
      }
      else
      {
        b = 0.0;
      }
      
      r += b*0.4;
      g += b*0.4;
      
      return vec3(r,g,b);
    }
    
    vec3 flip(vec3 v)
    {
      v.x *= -1.0;
      return v;
    }
    
    void main(void)
    {
      //vec3 flipped = vec2(vPos);
      //flipped.x *= -1.0;
      vec2 vTextureCoord = (vec2(vPos) + 1.0) / 2.0;
      float depth = texture2D(imageSampler, vTextureCoord).x;
      
      float ambientFactor = 0.3;
      float diffuseFactor = 0.2;
      float specularFactor = 0.4;
      float shininessFactor = 0.6;
      
      vec3 lightPos = vec3(-2, -2, -2);

      depth = normalizedTransform(depth);
      
      vec3 vColor = heatmap3c(depth);
      
      // ambient
      vec3 ambient = ambientFactor * vColor;
      
      // diffuse 
      vec3 norm = normalize(vNormal);
      vec3 lightDir = normalize(lightPos - flip(vPos));
      float diff = max(dot(norm, lightDir), 0.0);
      vec3 diffuse = diff * diffuseFactor * vColor;
      
      // specular
      vec3 viewDir = normalize(viewPos - flip(vPos));
      vec3 reflectDir = reflect(-lightDir, norm);  
      float spec = pow(max(dot(viewDir, reflectDir), 0.0), shininessFactor);
      vec3 specular = spec * specularFactor * vColor;
      
      vec3 result = ambient + diffuse + specular;
      gl_FragColor = vec4(result, 1.0);	
    }
    `;

    // Initialize a shader program; this is where all the lighting
    // for the vertices and so forth is established.
    const shaderProgram = this.initShaderProgram(gl, vsSource, fsSource);

    // Collect all the info needed to use the shader program.
    // Look up which attributes our shader program is using
    // for aVertexPosition, aVertexColor and also
    // look up uniform locations.
    const programInfo = {
      program: shaderProgram,
      attribLocations: {
        vertexPosition: gl.getAttribLocation(shaderProgram, "aVertexPosition")
      },
      uniformLocations: {
        projectionMatrix: gl.getUniformLocation(
          shaderProgram,
          "uProjectionMatrix"
        ),
        modelViewMatrix: gl.getUniformLocation(shaderProgram, "uModelViewMatrix"),
        invTModelViewMatrix: gl.getUniformLocation(
          shaderProgram,
          "uInvTModelViewMatrix"
        ),
        imageSampler: gl.getUniformLocation(shaderProgram, "imageSampler"),
        viewPos: gl.getUniformLocation(shaderProgram, "viewPos"),
        threshold: gl.getUniformLocation(shaderProgram, "threshold"),
        contrast: gl.getUniformLocation(shaderProgram, "contrast")
      }
    };

    // Here's where we call the routine that builds all the
    // objects we'll be drawing.
    var buffers = this.initBuffers(gl);
    var then = 0;
    var that = this;
    
    // Draw the scene repeatedly
    function render(now) {
      now *= 0.0001; // convert to seconds
      const deltaTime = now - then;
      then = now;

      if (that.imageLoaded) {
        const internalFormat = gl.RGBA;
        const srcFormat = gl.RGBA;
        const srcType = gl.UNSIGNED_BYTE;

        gl.bindTexture(gl.TEXTURE_2D, buffers.inputImage);
        gl.texImage2D(
          gl.TEXTURE_2D,
          0,
          internalFormat,
          srcFormat,
          srcType,
          that.footImage
        );
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

        that.imageLoaded = false;
        that.hasValidImage = true;
      }

      if (that.hasValidImage) {
        that.drawScene(gl, programInfo, buffers, deltaTime);
      }

      requestAnimationFrame(render);
    }

    requestAnimationFrame(render);
  }

  //
  // initBuffers
  //
  // Initialize the buffers we'll need. For this demo, we just
  // have one object -- a simple three-dimensional cube.
  //
  initBuffers(gl) {
    var positionsArray = new Float32Array(this.N * this.N * 3);

    var i = 0;
    for (var x = 0; x < this.N; x++) {
      for (var y = 0; y < this.N; y++) {
        positionsArray[i * 3 + 0] = (2.0 * x) / this.N - 1;
        positionsArray[i * 3 + 1] = (2.0 * y) / this.N - 1;
        positionsArray[i * 3 + 2] = 0;

        i += 1;
      }
    }

    // Now pass the list of positions into WebGL to build the
    // shape. We do this by creating a Float32Array from the
    // JavaScript array, then use it to fill the current buffer.

    const positionBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, positionsArray, gl.DYNAMIC_DRAW);

    const inputImage = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, inputImage);

    {
      const level = 0;
      const internalFormat = gl.RGBA;
      const width = 1;
      const height = 1;
      const border = 0;
      const srcFormat = gl.RGBA;
      const srcType = gl.UNSIGNED_BYTE;
      const pixel = new Uint8Array([0, 0, 255, 255]); // opaque blue
      gl.texImage2D(
        gl.TEXTURE_2D,
        level,
        internalFormat,
        width,
        height,
        border,
        srcFormat,
        srcType,
        pixel
      );
    }

    // Build the element array buffer; this specifies the indices
    // into the vertex arrays for each face's vertices.

    const indexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);

    var indices;
    
    if (this.gl2Mode)
    {
      indices = new Uint32Array((this.N - 1) * (this.N - 1) * 2 * 3);
    }
    else
    {
      indices = new Uint16Array((this.N - 1) * (this.N - 1) * 2 * 3);
    }
    
    var k = 0;
    for (var i = 0; i < this.N - 1; i++) {
      for (var j = 0; j < this.N - 1; j++) {
        var a = i * this.N + j;
        var b = (i + 1) * this.N + j;
        var c = i * this.N + (j + 1);
        var d = (i + 1) * this.N + (j + 1);

        indices[k * 6 + 0] = a;
        indices[k * 6 + 1] = b;
        indices[k * 6 + 2] = c;

        indices[k * 6 + 3] = d;
        indices[k * 6 + 4] = c;
        indices[k * 6 + 5] = b;

        k += 1;
      }
    }

    // Now send the element array to GL

    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, indices, gl.STATIC_DRAW);

    return {
      vertexCount: indices.length,
      inputImage: inputImage,
      position: positionBuffer,
      indices: indexBuffer
    };
  }

  addAngles(alpha, beta) {
    this.rotX += alpha;
    this.rotY -= beta;
  }

  setThreshold(newThreshold) {
    // console.log(threshold, newThreshold);
    this.threshold = newThreshold;
  }

  setContrast(newContrast) {
    // console.log(contrast, newContrast);
    this.contrast = newContrast;
  }

  //
  // Draw the scene.
  //
  drawScene(gl, programInfo, buffers) {
    gl.clearColor(0.3, 0.3, 0.3, 1.0); // Clear to gray, fully opaque
    gl.clearDepth(1.0); // Clear everything
    gl.enable(gl.DEPTH_TEST); // Enable depth testing
    gl.depthFunc(gl.LEQUAL); // Near things obscure far things

    // Clear the canvas before we start drawing on it.

    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    // Create a perspective matrix, a special matrix that is
    // used to simulate the distortion of perspective in a camera.
    // Our field of view is 45 degrees, with a width/height
    // ratio that matches the display size of the canvas
    // and we only want to see objects between 0.1 units
    // and 100 units away from the camera.

    const fieldOfView = (20 * Math.PI) / 180; // in radians
    const aspect = gl.canvas.clientWidth / gl.canvas.clientHeight;
    const zNear = 0.1;
    const zFar = 100.0;
    const projectionMatrix = mat4.create();

    // note: glmatrix.js always has the first argument
    // as the destination to receive the result.
    mat4.perspective(projectionMatrix, fieldOfView, aspect, zNear, zFar);

    mat4.translate(projectionMatrix, projectionMatrix, [-0.0, 0.0, -6.0]);
    mat4.rotate(projectionMatrix, projectionMatrix, this.rotX, [0, 1, 0]);
    mat4.rotate(projectionMatrix, projectionMatrix, this.rotY, [1, 0, 0]);
    mat4.translate(projectionMatrix, projectionMatrix, [-0.0, 0.0, 6.0]);

    // Set the drawing position to the "identity" point, which is
    // the center of the scene.
    const modelViewMatrix = mat4.create();

    // Now move the drawing position a bit to where we want to
    // start drawing the square.

    mat4.translate(modelViewMatrix, modelViewMatrix, [-0.0, 0.0, -6.0]);

    const invTModelViewMatrix = mat4.create();

    mat4.invert(invTModelViewMatrix, modelViewMatrix);
    mat4.transpose(invTModelViewMatrix, invTModelViewMatrix);

    {
      const numComponents = 3;
      const type = gl.FLOAT;
      const normalize = false;
      const stride = 0;
      const offset = 0;
      gl.bindBuffer(gl.ARRAY_BUFFER, buffers.position);
      gl.vertexAttribPointer(
        programInfo.attribLocations.vertexPosition,
        numComponents,
        type,
        normalize,
        stride,
        offset
      );
      gl.enableVertexAttribArray(programInfo.attribLocations.vertexPosition);
    }

    // Tell WebGL to use our program when drawing

    gl.useProgram(programInfo.program);

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, buffers.inputImage);
    gl.uniform1i(programInfo.uniformLocations.imageSampler, 0);

    gl.uniform3f(programInfo.uniformLocations.viewPos, 0.0, 0.0, 6.0);

    gl.uniform1f(programInfo.uniformLocations.threshold, this.threshold);
    gl.uniform1f(programInfo.uniformLocations.contrast, this.contrast);

    // Set the shader uniforms

    gl.uniformMatrix4fv(
      programInfo.uniformLocations.projectionMatrix,
      false,
      projectionMatrix
    );
    gl.uniformMatrix4fv(
      programInfo.uniformLocations.modelViewMatrix,
      false,
      modelViewMatrix
    );
    gl.uniformMatrix4fv(
      programInfo.uniformLocations.invTModelViewMatrix,
      false,
      invTModelViewMatrix
    );

    // Tell WebGL which indices to use to index the vertices
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indices);
    
    if (this.gl2Mode)
    {
      gl.drawElements(gl.TRIANGLES, buffers.vertexCount, gl.UNSIGNED_INT, 0);
    }
    else
    {
      gl.drawElements(gl.TRIANGLES, buffers.vertexCount, gl.UNSIGNED_SHORT, 0);
    }
  }

  //
  // Initialize a shader program, so WebGL knows how to draw our data
  //
  initShaderProgram(gl, vsSource, fsSource) {
    const vertexShader = this.loadShader(gl, gl.VERTEX_SHADER, vsSource);
    const fragmentShader = this.loadShader(gl, gl.FRAGMENT_SHADER, fsSource);

    // Create the shader program

    const shaderProgram = gl.createProgram();
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);

    // If creating the shader program failed, alert

    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
      alert(
        "3D program betöltése sikertelen: " + gl.getProgramInfoLog(shaderProgram)
      );
      return null;
    }

    return shaderProgram;
  }

  //
  // creates a shader of the given type, uploads the source and
  // compiles it.
  //
  loadShader(gl, type, source) {
    const shader = gl.createShader(type);

    // Send the source to the shader object

    gl.shaderSource(shader, source);

    // Compile the shader program

    gl.compileShader(shader);

    // See if it compiled successfully

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      alert(
        "Nem sikerült lefordítani a 3D programot: " + gl.getShaderInfoLog(shader)
      );
      gl.deleteShader(shader);
      return null;
    }

    return shader;
  }
}
