<template>
  <div class="3d" style="height: calc(100% - 60px - 99px - 12px)">
    <div style="height: calc(100% - 76px)" class="pb-3 mb-3">
      <v-card
        :width="`${zoom}%`"
        style="transition: width 0.3s"
        class="mx-auto"
      >
        <canvas
          id="glcanvas"
          width="800"
          height="600"
          style="width: 100%; vertical-align: bottom"
          ref="canvas"
        ></canvas>
      </v-card>
    </div>
    <v-toolbar
      dark
      color="primary"
      class="mx-auto"
      style="position: sticky; bottom: 1.5rem"
      rounded
    >
      <v-slider
        :label="$t('threshold')"
        hide-details
        v-model="threshold"
        class="ml-3"
        min="0"
        max="255"
      />
      <v-slider
        :label="$t('contrast')"
        hide-details
        v-model="contrast"
        class="ml-3"
        min="1"
        max="160"
      />
      <v-slider
        :label="$t('zoom')"
        hide-details
        v-model="zoom"
        class="ml-3"
        min="30"
        max="100"
      />
      <v-toolbar-items>
        <v-btn icon dark @click="reset">
          <v-icon>mdi-rotate-left</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>
<script>
import RecordMixin from "@/mixins/RecordMixin";
import {
  Feet3D,
} from "@/utils/webgl-demo";

export default {
  name: "View3D",
  mixins: [RecordMixin],
  data: () => ({
    feet3d_engine: null,
    feetImage: null,
    threshold: 127,
    contrast: 80,
    zoom: 70,
    startPos: {} // mouse start position
  }),
  mounted() {
    this.feet3d_engine = new Feet3D;
    if (this.feetImage)
    {
      this.renderFeetImage();
    }
    else
    {
      this.downloadFeetImage();
    }
    this.feet3d_engine.rendererMain();
    this.$refs.canvas.addEventListener("mousemove", this.onMouseEvent);
    this.$refs.canvas.addEventListener("mousedown", this.onMouseEvent);
  },
  methods: {
    async downloadFeetImage() {
      if (!this.localRecord || !this.localRecord.feetImage) return;
      const imageData = await this.$store.dispatch("download", {
        PatientID: this.localRecord.PatientID,
        FileName: this.localRecord.feetImage
      });
      this.feetImage = imageData;
      this.renderFeetImage();
    },
    renderFeetImage() {
      var src = "data:image/jpeg;base64," + this.feetImage.data;
      this.feet3d_engine.loadImage(src);
      //if (reload) window.setTimeout(onImageLoad, 200);
    },
    reset() {
      this.colorScheme = "color";
      this.threshold = 127;
      this.contrast = 80;
      this.zoom = 70;
    },
    onMouseEvent(ev) {
      const pos = {
        x: ev.offsetX,
        y: ev.offsetY
      };

      switch (ev.type) {
        case "mousedown":
          if (ev.which === 1) this.startPos = pos;
          break;
        case "mousemove":
          if (ev.which === 1) {
            const delta = {
              x: pos.x - this.startPos.x,
              y: pos.y - this.startPos.y
            };

            this.startPos = pos;

            this.feet3d_engine.addAngles(delta.x / 100.0, delta.y / 100.0);
          } else this.startPos = pos;
          break;
        default:
          break;
      }
    },
  },
  watch: {
    threshold: function(value) {this.feet3d_engine.setThreshold(value);},
    contrast: function(value) {this.feet3d_engine.setContrast(value);},
    localRecord() {
      this.downloadFeetImage();
    }
  }
};
</script>
